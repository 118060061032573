<template>
  <div class="container">
  <div class=" row mt-5">
    <div class="col-md-1">
    </div>
     <div class="card col-md-5">
      <TypeOne/>
    </div>
    <div class="col-md-1">
    </div>
    <div class="card col-md-5">
<TypeTwo/>
    </div>
    <div class="col-md-1">
    </div>
    <div class="card col-md-5 mt-5">
<TypeThree/>
    </div>
  </div>
</div>
</template>

<script>
import TypeOne from '../components/TypeOne.vue'
import TypeTwo from '../components/TypeTwo.vue'
import TypeThree from '../components/TypeThree.vue'

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components:{
    TypeOne,
    TypeTwo,
    TypeThree
  },
  data() {
    return {
     
    };
  },
  mounted() {
  
  },
  methods: {
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
