<template>
  <div class="container" v-if="show">
      <apexchart type="bar" :options="options" :series="series"></apexchart>
    </div>
</template>

<script>
import CountService from "../services/CountService";
import UrlService from "../services/UrlService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components:{
  },
  data() {
    return {
      show:false,
      list:[],
      options: {
        title: {
          text: "กราฟแสดงจำนวนผู้เข้าลิงก์รายสัปดาห์",
        },
        chart: {
          id: 'vuechart-example',
          toolbar: {
      show: false
    },
        },
        
        xaxis: {
          categories: []
        }
      },
      series: [{
        name:'',
        data: []
      }],
      title:''
    };
  },
  mounted() {
this.getUrl()

  },
  methods: {
    async getCount(){

      // var date  = new Date()
// console.log(date);
// var day = date.getDate()
// var month = date.getMonth()+1
// var year = date.getFullYear()
// var full = year+'-'+("0" + (month)).slice(-2)+'-'+("0" + (day)).slice(-2)
// console.log(full);
// console.log(this.list);
var no = []
var curr = new Date; // get current date
var first = curr.getDate() - curr.getDay()+1; // First day is the day of the month - the day of the week
var last = first + 4; // last day is the first day + 6

var firstday = new Date(curr.setDate(first));
var lastday = new Date(curr.setDate(last));

var firsttext = firstday.getFullYear()+'-'+("0" + (firstday.getMonth()+1)).slice(-2)+'-'+("0" + (firstday.getDate())).slice(-2)
var lastdate = lastday.getFullYear()+'-'+("0" + (lastday.getMonth()+1)).slice(-2)+'-'+("0" + (lastday.getDate())).slice(-2)
// console.log(firsttext,lastdate);
this.title = 'กราฟแสดงจำนวนผู้เข้าลิงก์รายสัปดาห์ ระหว่างวันที่ '+ firstday.toLocaleDateString('th-TH', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}) +' - '+ lastday.toLocaleDateString('th-TH', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})
this.options.title.text = this.title
for (let c = 0; c < this.list.length; c++) {
  await CountService.getcounts('',this.list[c].id,firsttext,lastdate,'','').then((res)=>{
    no.push(res.data[0].count)
    if (c+1 == this.list.length) {
      // console.log(no);
      this.series[0].data = no
      this.show = true
    }
});
  
}
    },
    getUrl(){
      UrlService.geturls("").then((res) => {
this.list = res.data
        // console.log(res.data);
        var dt = []
        for (let r = 0; r < res.data.length; r++) {
          dt.push(res.data[r].name)
          if (r+1 == res.data.length) {
            // console.log(dt);
        this.options.xaxis.categories = dt;
            this.getCount()
          }
        }
      });
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
