
class LinkService {
  geturl() {
    return 'https://api-tmdservice.ci2ict.com/'
    // return 'http://localhost:8081';
  }
}

export default new LinkService();

