<template>
  <div class="container">
  <div class="row mt-5">
     <div class="col-md-3 text-center" v-for="(l,i) in list" :key="i">
      <a :href="l.url" target="_blank">
          <button
          @click="gotopage(l)"
            class="btn btn-success btn-sm mt-3"
            style="color: white;width:250px;height:150px;white-space: normal;"
          >
          <h5> {{l.name}}</h5>
          </button>
      </a>
          &nbsp;
    </div>
  </div>
</div>
</template>

<script>
import UrlService from "../services/UrlService";
import CountService from "../services/CountService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components:{
  },
  data() {
    return {
      list:[]
    };
  },
  mounted() {
    this.getURL()
  
  },
  methods: {
    gotopage(data){
// console.log(data);
var date  = new Date()
// console.log(date);
var day = date.getDate()
var month = date.getMonth()+1
var year = date.getFullYear()
var full = year+'-'+("0" + (month)).slice(-2)+'-'+("0" + (day)).slice(-2)
// console.log(full);

CountService.getcounts(full,data.id,'','','','').then((res)=>{
  // console.log(res.data);
  // let urlcheck = data.url.includes("https://")
  // var url = data.url
  // console.log(url);
  // if (!urlcheck) {
  //       url = 'https://'+data.url
  //     }
  //     console.log(url);
  if (res.data[0].id == null) {
    var countsdata = {
      urlId:data.id,
      no:1,
      date:full
    }
    // console.log(countsdata);
    CountService.createcount(countsdata).then(()=>{
      // console.log(res.data);
      // window.open(url, '_system');
    })
  }else{
    var updatecountsdata = {
      no:res.data[0].no+1
    }
    CountService.updatecount(res.data[0].id,updatecountsdata).then(()=>{
      // console.log(res.data);
      // window.open(url, '_system');
    })
  }
})
    },
    getURL(){
      UrlService.geturls().then((res)=>{
        this.list = res.data
      })
    }
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
