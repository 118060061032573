import http from "../http-common";

class UrlService {
  createurl(data) {
    return http.post('/url', data);
  }
  geturls() {
    return http.get(`/url`);
  }
  geturl(id) {
    return http.get(`/url/` + id);
  }
  updateurl(id, data) {
    return http.put(`/url/${id}`, data);
  }
  deleteurl(id) {
    return http.delete(`/url/` + id);
  }
}

export default new UrlService();