import http from "../http-common";

class CountService {
  createcount(data) {
    return http.post('/counts', data);
  }
  getcounts(name,id,start,end,month,year) {
    return http.get(`/counts?name=`+name+"&&id="+id+"&&start="+start+"&&end="+end+"&&month="+month+"&&year="+year);
  }
  getcount(id) {
    return http.get(`/counts/` + id);
  }
  updatecount(id, data) {
    return http.put(`/counts/${id}`, data);
  }
}

export default new CountService();