<template>
  <div class="container">
    <div class="col mb-3 mt-3" style="text-align: right">
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
          @click="getid(0)"
          data-bs-toggle="modal"
          data-bs-target="#AddUser"
        >
          <i class="fa fa-plus"></i> เพิ่ม URL
        </button></a
      >
    </div>
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">URL</th>
          <th scope="col">ชื่อ URL</th>
          <th scope="col">Qrcode</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }}
          </td>

          <td>
            {{ l.url }}
          </td>

          <td>
            {{ l.name }}
          </td>
          <td>
            <qr-code :text="l.url" :size=100> </qr-code>
          </td>
          <td>
            <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#AddUser"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp;
            <button
            @click="deleteurl(l.id)"
                type="button"
                class="btn btn-danger"
              >
                <i class="fa fa-trash"></i></button
            >
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div
      class="modal fade"
      id="AddUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <!-- <div class="form-group mt-3">
                  <label for="username">ชื่อ</label>
                  <input
                    v-model="user.firstname"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอกชื่อ"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="username">นามสกุล</label>
                  <input
                    v-model="user.lastname"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอกนามสกุล"
                  />
                </div> -->
                <div class="form-group mt-3">
                  <label for="username">URL</label>
                  <input
                    v-model="user.url"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอก URL"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="username">ชื่อ URL</label>
                  <input
                    v-model="user.name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอกชื่อ URL"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
              id="closeduser"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UrlService from "../services/UrlService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
      roles: [],
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    deleteurl(id){
UrlService.deleteurl(id).then(()=>{
  this.getUsers();
})
    },
    getid(id) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขข้อมูล URL";
        // console.log(this.user_id);
        UrlService.geturl(this.user_id).then((res) => {
          // console.log(res.data);
          this.user = res.data;
          this.hash = this.user.password;
        });
      } else {
        this.title = "เพิ่มข้อมูล URL";
        this.user = [];
      }
    },
    save() {
      // if (this.user.firstname == "") {
      //   alert("กรุณากรอกชื่อ");
      // } else if (this.user.lastname == "") {
      //   alert("กรุณากรอกนามสกุล");
      // }else
      if (this.user.url == "" || this.user.url == null) {
        alert("กรุณากรอก URL");
        // } else if (this.user.role_id == "") {
        //   alert("กรุณาเลือกสิทธิ์");
      } else if (this.user.name == "" || this.user.name == null) {
        alert("กรุณากรอกชื่อ URL");
      } else {
        var userdata = {
          url: this.user.url,
          name: this.user.name,
          createdBy: this.currentUser.id,
          status:1
        };
        if (this.user_id == 0) {
          UrlService.createurl(userdata).then(() => {
            document.getElementById("closeduser").click();
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        } else {
          // console.log(this.user_id);
          UrlService.updateurl(this.user_id, userdata).then(() => {
            // console.log(res.data);
            document.getElementById("closeduser").click();
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
    getUsers() {
      UrlService.geturls("").then((res) => {
        this.list = res.data;
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
